import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { watch } from 'vue';
import { usePreferStore } from '@/store/modules/preference.js';

const version = "1.0.5";
const buildTime = "2022.05.03";

export default {
  __name: 'App',
  setup(__props) {

const preferStore = usePreferStore();

watch(() => preferStore.theme, () => {
  document.body.setAttribute('class', preferStore.theme);
}, { immediate: true });

console.log(
  `%c  Release Build Info        
%c  Version			v${version}  
  BuildTime		${buildTime}  `,
  "background:#000;color:#FFF;font-weight:bold;",
  "background:#FFF;color:#000;"
);

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_component_router_view))
}
}

}